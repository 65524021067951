<template>
    <v-container class="px-0 py-0">
        <v-row>
            <v-col cols="12">
                <v-data-table
                    v-if="cart"
                    hide-default-footer
                    :items="cart.items"
                    :headers-length="5"
                    class="elevation-0"
                    :items-per-page="-1"
                >
                    <template v-slot:no-data>
                        El pedido esta vacio
                    </template>

                    <template v-slot:item="props">
                        <tr>
                            <td style="width:96px;" v-if="$vuetify.breakpoint.smAndUp">
                                <v-img
                                    height="96"
                                    width="96"
                                    :src="props.item.product.thumbnail | toCDNPath"
                                    contain
                                ></v-img>
                            </td>
                            <td>{{ props.item.quantity }} x {{ props.item.product.name | capitalize }}</td>
                            <td style="width:96px;"></td>
                            <td>{{ props.item.product.price | toCurrency }}</td>
                            <td>{{ (props.item.product.price * props.item.quantity) | toCurrency }}</td>
                            <td>
                                <v-btn text icon @click="remove(props.item)">
                                    <v-icon small>mdi-close</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>

                    <template v-slot:body.prepend>
                        <tr class="input-row">
                            <td></td>

                            <td class="pt-5">
                                <product-select
                                    ref="productField"
                                    v-model="product"
                                    :products="products"
                                    autocomplete="please-dont-autofill"
                                >
                                </product-select>
                            </td>

                            <td class="pt-5">
                                <v-text-field
                                    ref="quantityField"
                                    label="Cantidad"
                                    v-model="quantity"
                                    @keydown.enter="add"
                                ></v-text-field>
                            </td>

                            <td class="pt-5">
                                <v-text-field label="Precio" disabled v-model="toAddPrice"></v-text-field>
                            </td>

                            <td class="pt-5">
                                <v-text-field label="Subtotal" disabled v-model="toAddTotalPrice"></v-text-field>
                            </td>

                            <td class="pt-5">
                                <v-btn text icon
                                    ref="addButton"
                                    color="primary"
                                    @click="add"
                                    :disabled="!product || !quantity"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ProductSelect from '@/components/Product/ProductSelect';

    export default {
        components: { ProductSelect },

        props: {
            customer: Object,
            cart: Object
        },

        data() {
            return {
                product: null,
                quantity: 1
            };
        },

        watch: {
            product(v) {
                if(v) {
                    this.$nextTick(() => {
                        this.$refs.quantityField.focus();
                    });
                }
            }
        },

        computed: {
            isLoading() {
                return this.$store.getters['products/isLoading']() || this.$store.getters['stock/isLoading']();
            },

            products() {
                if(!this.customer) {
                    return [];
                }

                return this.$store.getters['products/getAll']().filter(product => {
                    const stock = this.$store.getters['stock/getByProductId'](product.id);
                    const hasStock = stock ? stock.currentStock > 0 : true;

                    return hasStock && product.isWholesaler === this.customer.isWholesaler;
                });
            },

            toAddPrice() {
                if(!this.product) {
                    return '';
                }

                return this.$options.filters.toCurrency(this.product.price);
            },

            toAddTotalPrice() {
                if(!this.product) {
                    return '';
                }

                return this.$options.filters.toCurrency(this.product.price * this.quantity);
            }
        },

        mounted() {
            // fetch stocks
            this.$store.dispatch('stock/fetchAll');
            this.$store.dispatch('products/fetchAll');
        },

        methods: {
            add() {
                if(this.quantity > 0) {
                    this.$store.dispatch('cart/add', {
                        cart: this.cart,
                        item: {
                            product: this.product.id,
                            quantity: this.quantity
                        }
                    });
                }
                else {
                    this.$store.dispatch('cart/remove', {
                        cart: this.cart,
                        item: {
                            product: this.product.id,
                            quantity: -this.quantity
                        }
                    });
                }

                // reset
                this.quantity = 1;
                this.product = null;

                // re-focus on the product list
                setTimeout(() => {
                    this.$refs.productField.focus();
                }, 100);
            },

            remove(item) {
                this.$store.dispatch('cart/remove', {
                    cart: this.cart,
                    item: {
                        product: item.product.id,
                        quantity: item.quantity
                    }
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-data-table {
        tbody {
            tr {
                &.input-row {
                    &:hover {
                        background-color: transparent !important;
                    }
                }
            }
        }
    }
</style>

<template>
    <template-layout>
        <template slot="content">
            <v-container v-if="cart">
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Modificar Pedido {{ order.id }}</span>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title>
                                <span class="title">Pedido</span>
                            </v-card-title>

                            <v-card-text>
                                <order-editable-cart
                                    :cart="cart"
                                    :customer="customer"
                                ></order-editable-cart>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-card-title>Total</v-card-title>

                            <v-card-text class="body-1">
                                <v-row v-for="(error,key) in cart.errors" v-bind:key="key">
                                    <v-col cols="12">
                                        <span class="red--text">{{ $t(error) }}</span>
                                    </v-col>
                                </v-row>

                                <v-row v-for="(error,key) in errors" v-bind:key="key">
                                    <v-col cols="12">
                                        <span class="red--text">{{ $t(error) }}</span>
                                    </v-col>
                                </v-row>

                                <v-row v-if="cart">
                                    <v-list-item>
                                        <v-list-item-content>Subtotal</v-list-item-content>
                                        <v-list-item-content class="align-end">{{ cart.subtotal | toCurrency }}</v-list-item-content>
                                    </v-list-item>

                                    <v-list-item v-for="(discount, key) in cart.discounts" v-bind:key="key">
                                        <v-list-item-content>{{ discount.name }}</v-list-item-content>
                                        <v-list-item-content class="align-end">{{ -discount.amount | toCurrency }}</v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>Total</v-list-item-content>
                                        <v-list-item-content class="align-end">{{ cart.total | toCurrency }}</v-list-item-content>
                                    </v-list-item>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-card>
                            <v-card-title>Facturación</v-card-title>

                            <v-card-text>
                                <customer-billing-address-select
                                    v-model="billingAddress"
                                    :customer="customer"
                                    show-add
                                    @add-selected="onAddBillingAddressSelected"
                                    @edit-selected="onEditBillingAddressSelected"
                                />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="text-right">
                        <v-btn
                            color="primary"
                            :disabled="!isValid"
                            @click="onSave">
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import CustomerBillingAddressSelect from '@/components/Customer/CustomerBillingAddressSelect';
    import OrderEditableCart from '@/components/Order/OrderEditableCart';

    export default {
        components: {
            TemplateLayout,
            OrderEditableCart,
            CustomerBillingAddressSelect
        },

        data() {
            return {
                showShippingAddressCreateDrawer: false,
                showBillingAddressCreateDrawer: false,
                showBillingAddressEditDrawer: false,

                orderTypes: [
                    { name: 'Normal', value: 'normal' },
                    { name: 'Reposición', value: 'support' }
                ],

                orderType: null,
                orderClaim: null,
                customerSearch: null,
                customer: null,
                cartId: null,
                shippingAddress: null,
                deliveryZone: null,
                deliverySchedule: null,
                billingAddress: null,
                deliveryFeatures: [],
                errors: []
            };
        },

        computed: {
            cart() {
                return this.$store.getters['cart/get']();
            },

            isValid() {
                if(!this.customer || !this.cart) {
                    return false;
                }

                if(!this.shippingAddress) {
                    return false;
                }

                if(!this.deliverySchedule) {
                    return false;
                }

                if(this.cart.errors.length > 0 && this.cart.errors.includes('error.order.billing_required')) {
                    return false;
                }

                if(!this.cart.items || this.cart.items.length === 0) {
                    return false;
                }

                if(this.orderType === 'support' && !this.orderClaim) {
                    return false;
                }

                return true;
            },

            vehicleFeatures() {
                return this.$store.getters['vehicleFeatures/getAll']();
            }
        },

        watch: {
            async customerSearch(value, prev) {
                if(prev && value.id === prev.id) {
                    return;
                }

                await this.$store.dispatch('customer/fetchOne', value.id);

                this.customer = this.$store.getters['customer/getById'](value.id);
            },

            orderType(value) {
                this.$store.dispatch('cart/setOrderType', {
                    cart: this.cart,
                    orderType: this.orderType
                });
            },

            customer(value) {
                if(value) {
                    // set customer in cart
                    this.$store.dispatch('cart/setCustomer', {
                        cart: this.cart,
                        customer: value
                    });
                }
            },

            shippingAddress(value) {
                this.deliveryZone = null;

                let loc = new google.maps.LatLng(value.location.lat, value.location.lng);

                this.$refs.map.clearMarkers();
                this.$refs.map.setCenter(loc);
                this.$refs.map.setMarker(loc);
                this.$refs.map.setZoom(16);

                this.$api.getZonesByLocation(value.location).then((zone) => {
                    this.deliveryZone = zone;
                });

                this.$store.dispatch('cart/setShippingAddress', {
                    cart: this.cart,
                    address: value
                });
            },

            billingAddress: {
                deep: true,
                handler(value) {
                    this.$store.dispatch('cart/setBillingAddress', {
                        cart: this.cart,
                        address: value
                    });
                }
            },

            deliverySchedule: {
                deep: true,
                handler(value) {
                    this.$store.dispatch('cart/setDeliverySchedule', {
                        cart: this.cart,
                        schedule: value
                    });
                }
            }
        },

        mounted() {
            this.$store.dispatch('cart/create');
            this.$store.dispatch('vehicleFeatures/fetchAll');

            this.orderType = this.orderTypes[0];
        },

        methods: {
            onAddShippingAddressSelected() {
                this.showShippingAddressCreateDrawer = true;
            },

            onAddBillingAddressSelected() {
                this.showBillingAddressCreateDrawer = true;
            },

            onEditBillingAddressSelected() {
                this.showBillingAddressEditDrawer = true;
            },

            async onSave() {
                // try {
                let extra = {
                    ...(this.orderClaim ? { claim: this.orderClaim.id } : {})
                };

                let items = this.cart.items.map(cartItem => {
                    return {
                        product: cartItem.product.id,
                        quantity: cartItem.quantity
                    };
                });

                let result = await this.$store.dispatch('order/create', {
                    type: this.orderType.value,
                    customer_id: this.customer.id,
                    billing_address_id: this.billingAddress.id,
                    shipping_address_id: this.shippingAddress.id,
                    shipping_date: this.deliverySchedule.date,
                    shipping_start_time: this.deliverySchedule.start,
                    shipping_end_time: this.deliverySchedule.end,
                    vehicleFeatures: this.deliveryFeatures.map(item => item.id),
                    items: items,
                    extra: extra
                });

                this.$router.replace({ name: 'orderView', params: { id: result } });
                // }
                // catch (err) {
                //     this.errors = [];
                //     this.errors.push(err.code);
                // }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-subheader {
        padding: 0 !important;
    }
</style>

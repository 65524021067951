<template>
    <v-autocomplete
        ref="field"
        v-model="product"
        :items="products"
        :loading="isLoading"
        hide-no-data
        hide-selected
        item-text="name"
        label="Producto"
        placeholder=""
        return-object
        auto-select-first
    >
        <template v-slot:item="item">
            <v-list-item-avatar
                class="headline font-weight-light white--text"
                tile
            >
                <v-img
                    height="96"
                    width="96"
                    :src="getImagePath(item.item)"
                    contain
                >
                </v-img>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title v-text="item.item.name"></v-list-item-title>
                <v-list-item-subtitle v-text="item.item.presentation"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                {{ item.item.price | toCurrency }}
            </v-list-item-action>

            <v-list-item-action>
                <span v-if="getStockForProduct(item.item)" :class="getStockForProduct(item.item).currentStock < getStockForProduct(item.item).minimumStockLevel ? 'red--text' : ''">
                    <v-icon :color="getStockForProduct(item.item).currentStock < getStockForProduct(item.item).minimumStockLevel ? 'red' : ''">mdi-package-variant-closed</v-icon>
                    {{ getStockForProduct(item.item).currentStock }}
                </span>
            </v-list-item-action>
        </template>
    </v-autocomplete>
</template>

<script>
    export default {
        components: {},

        props: {
            value: Object,
            products: Array
        },

        data() {
            return {
                product: null,

                isLoading: false,

                search: '',
                searchResults: []
            };
        },

        mounted() {

        },

        watch: {
            value(v) {
                this.product = v;
            },

            product(v) {
                this.$emit('input', v);
            }
        },

        methods: {
            focus() {
                this.$refs.field.focus();
            },

            getStockForProduct(product) {
                return this.$store.getters['stock/getByProductId'](product.id);
            },

            getImagePath(product) {
                if(product.thumbnail) {
                    return process.env.VUE_APP_TALLO_CDN + '/' + product.thumbnail;
                }
                else {
                    return require('@/assets/blank_img.png');
                }
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>

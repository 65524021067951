<template>
    <v-select
        label="Dirección fiscal"
        v-model="address"
        :items="addresses"
        item-text="address"
        item-value="id"
        :disabled="!this.customer"
        return-object
        required
    >
        <template slot="selection" slot-scope="data">
            <template v-if="data.item.id">
                {{ data.item.name }} - {{ data.item.ivaCondition }} - {{ data.item.cuit }}
            </template>
            <template v-else>
                {{ data.item.name }}
            </template>
        </template>
        <template slot="item" slot-scope="data">
            <template v-if="data.item.id">
                {{ data.item.name }} - {{ data.item.ivaCondition }} - {{ data.item.cuit }}
            </template>
            <template v-else>
                {{ data.item.name }}
            </template>
        </template>
        <template v-if="showAdd" v-slot:append-outer>
            <v-btn icon @click="onAddSelected">
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn icon @click="onEditSelected">
                <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
        </template>
    </v-select>
</template>

<script>
    export default {
        components: {},

        props: {
            customer: {
                type: Object
            },
            value: {
                type: Object
            },
            showAdd: {
                type: Boolean
            }
        },

        data() {
            return {
                isLoading: false,
                address: null
            };
        },

        computed: {
            addresses() {
                if(!this.customer) {
                    return [];
                }

                return this.customer.billingAddresses ? this.customer.billingAddresses : [];
            }
        },

        watch: {
            value(v) {
                this.address = v;
            },

            address(value) {
                this.$emit('input', value);
            },

            addresses(value) {
                if(value && value.length > 0) {
                    this.address = value[0];
                }
            },

            customer: {
                immediate: true,
                handler(value, oldValue) {
                    if(!value || !value.id || (oldValue && value.id === oldValue.id)) {
                        return;
                    }

                    this.isLoading = true;
                    this.$store.dispatch('customer/fetchBillingAddresses', { customer: this.customer });
                }
            }
        },

        methods: {
            onAddSelected() {
                this.$emit('add-selected');
            },

            onEditSelected() {
                this.$emit('edit-selected');
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
